import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SpadminComponent} from './spadmin/spadmin.component';
import {environment} from '../../environments/environment';
import {DashboardComponent} from './dashboard/dashboard.component';


const routes: Routes = [
  {
    path:  `:slladminId/${environment.master}`,
    component: SpadminComponent,
    data: {
      title: 'Admin'
    },
    children: [{
      path: 'dashboard',
      component: DashboardComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
