import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router, RouterModule, Routes} from '@angular/router';
import {
  FormioAuth,
  FormioAuthRoutes,
  FormioAuthService } from 'angular-formio/auth';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormioModule } from 'angular-formio';
import { AuthComponent } from './auth.component';
import { VerificationComponent } from './verification/verification.component';
import { VerifiedComponent } from './verified/verified.component';

const routes = [{
  path: '',
  component: AuthComponent,
  children: [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'verify', component: VerificationComponent},
    {path: 'verified', component: VerifiedComponent}
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    FormioAuth,
    FormioModule,
    RouterModule.forChild(routes)
  ],
  declarations: [LoginComponent, RegisterComponent, AuthComponent, VerificationComponent, VerifiedComponent]
})
export class AuthModule {}
