import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {ClientComponent} from './client.component'
import {FormviewComponent} from '../formview/formview.component';
import {DocumentsComponent} from './documents/documents.component';
import {AuthGuard} from '../shared/auth/auth-guard.service';
import {UnsureComponent} from './unsure/unsure.component';
import {PrintComponent} from './print/print.component';
import {SummaryComponent} from './summary/summary.component';

const routes: Routes = [
  {
    path: ':caseId',
    component: ClientComponent,
    data: {
      title: 'Client'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/view/:formname/:submissionId',
    component: FormviewComponent,
    data: {
      title: 'Form'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/unsure',
    component: UnsureComponent,
    data: {
      title: 'Form'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/documents',
    component: DocumentsComponent,
    data: {
      title: 'Documents'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/print',
    component: PrintComponent,
    data: {
      title: 'Print',
      callPrint: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/summary',
    component: SummaryComponent,
    data: {
      title: 'Summary',
      callPrint: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/_print',
    component: PrintComponent,
    data: {
      title: 'Print',
      callPrint: true,
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':caseId/_summary',
    component: SummaryComponent,
    data: {
      title: 'Summary',
      callPrint: true,
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule { }
