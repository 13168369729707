import { Component, OnInit } from '@angular/core';
import {FormioAuthService} from 'angular-formio/auth';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  ready = false;
  currentDate: any;

  constructor(public _auth: FormioAuthService, public _router: ActivatedRoute) { }

  ngOnInit() {
    this._auth.ready.then(res => {
      this.currentDate = moment().format('LLLL');
      this.ready = res;
    })
    this._router.params.subscribe(params => {
      console.log(params);
    });
  }

}
