import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Formio} from 'formiojs';
import {AppConfig} from '../../../config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormioAuthService} from 'angular-formio/auth';
import * as moment from 'moment';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: [
    './people.component.scss',
    './../../../assets/css/global.scss',
    './../../../assets/css/modals.scss'
  ],
  encapsulation:ViewEncapsulation.None
})
export class PeopleComponent implements OnInit {
  administrators: any[] = [];
  solicitors: any[] = [];
  clients: any[] = [];
  resource: string;
  src: string;
  editsrc =  {
    submissionUrl:"",
  };
  closeModal: any;
  administrator: any;
  currentDate: any;
  ready = false;
  params: any;
  newSolicitorForm = `${AppConfig.appUrl}/solicitorprofile`; // form url for create solicitor profile
  newAdminForm = `${AppConfig.appUrl}/administratorprofile`; // form url for create admin profile
  closeModalId = ''; // close modal bakground
  editSATitle = '';
  editSubmission = {}; // form io edit admin/solicitor form data - plugs into form io
  editForm = {} // // form io edit admin/solicitor form input strcture - plugs into form io
  editClientSubmission = {}; // form io edit admin/solicitor form data - plugs into form io
  editClientForm = {} // // form io edit admin/solicitor form input strcture - plugs into form io
  editFormResorce: any; // the form io obj looking after the admin or solicitor form
  editClientFormResorce: any; // the form io obj looking after the client form
  constructor(
    private modalService: NgbModal,
    public _auth: FormioAuthService,
    private _router: ActivatedRoute,
    private router: Router,
    
  ){}
  /*
  function gets all the people for the dahs board 
  error event is not handled
   */
  getPeople() {
    this.modalClose();
    const admin = new Formio(`${AppConfig.appUrl}/administratorprofile`);
    admin.loadSubmissions({params: {
        'data.trash__ne': true
      }}).then(admins => { this.administrators = admins; });
      
    const solicitors = new Formio(`${AppConfig.appUrl}/solicitorprofile`);
    solicitors.loadSubmissions({params: {
        'data.trash__ne': true
      }}).then(solicitor => { this.solicitors = solicitor; });
      
    const clients = new Formio(`${AppConfig.appUrl}/clientprofile`);
    clients.loadSubmissions({params: {
        'data.trash__ne': true 
      }}).then(client => { this.clients = client; });
  }
  
  deleteClient(c) {
    // this.administrator.data.trash = true;
    // this.editsrc.saveSubmission(this.administrator).then(() => {c(); this.closeModal(); this.getPeople();});
  }
  
  softDeleteSubmission ( formio, submission ) {
    submission.data.trash = true;
    formio.saveSubmission(submission).then( () => {
      this.getPeople();
    });
  }
  
  saveSubmission ( formio, submission ) {
    formio.saveSubmission(submission).then( () => {
      this.getPeople();
    });
  }
  
  openDelete(template, close) {
    // this.closeModal = close;
    // this.modalService.open(template);
  }
  /*
    show modal function 
   */
  modalOpen (id) {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-close')
    document.getElementById(id).classList.remove('jm-modal-close')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-open')
    document.getElementById(id).classList.add('jm-modal-open')
    this.closeModalId = id // the close id for the modal
  }
  /*
    close modal function 
   */
  modalClose () {
    
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-open')
    if (this.closeModalId.length > 0) {
      document.getElementById(this.closeModalId).classList.remove('jm-modal-open')
    }
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-close')
    if (this.closeModalId.length > 0){
      document.getElementById(this.closeModalId).classList.add('jm-modal-close')
    }
  }
  /*
    open edit function for modal
    error handling not handled
   */
  openEdit(resource, id) {
    if (resource == 'solicitorprofile'){
      this.editSATitle = 'solicitor'
    } else {
      this.editSATitle = 'administrator'
    }
    let formio = new Formio(`${AppConfig.appUrl}/${resource}/submission/${id}`); // form request 
    this.editFormResorce = formio;
    formio.loadForm().then((form) => { // fetch form strcture
      this.editForm = form 
      formio.loadSubmission().then((submission) => { // fetch data
        this.editSubmission = submission
        this.modalOpen('openEditModal');
      });
    });
    // skip and open modal
  
  }
  
  openEditClient(clientId) {
    console.log(clientId)
    let formio =  new Formio(`${AppConfig.appUrl}/clientprofile/submission/${clientId}`);
    this.editClientFormResorce = formio;
    formio.loadForm().then((form) => { // fetch form strcture
      this.editClientForm = form 
      formio.loadSubmission().then((submission) => { // fetch data
        this.editClientSubmission = submission
        this.modalOpen('openEditClientModal');
      });
    });
    // skip and open modal
  
  }
  
  openDashPage (childRoute) {
    let id = this._auth.user._id
    this.router.navigateByUrl(`/admin/${id}/${childRoute}`);
  }
  
  ngOnInit() {
    this._auth.ready.then(res => {
      this.currentDate = moment().format('LLLL');
      this.ready = res;
    })
    this._router.params.subscribe(params => {
      this.params = params;
      this.getPeople();
    });
  }

}
