import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import {AdminRoutingModule} from './admin-routing.module';
import {SharedModule} from '../shared/shared.module';
import { MatterComponent } from './matter/matter.component';
import { PeopleComponent } from './people/people.component';
import { ArchiveComponent } from './archive/archive.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CreateComponent } from './people/create/create.component';
import { EditComponent } from './people/edit/edit.component';
import {FormioModule} from 'angular-formio';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {FormsModule} from '@angular/forms';
import { ClientComponent } from './client/client.component';
import {PrintComponent} from './client/print/print.component';
import {UnsureComponent} from './client/unsure/unsure.component';
import {SummaryComponent} from './client/summary/summary.component';
import {DocumentsComponent} from './client/documents/documents.component';
import {FormviewComponent} from './client/formview/formview.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    FormioModule,
    FilterPipeModule,
    FormsModule,
    NgbModule.forRoot(),
  ],
  declarations: [AdminComponent, MatterComponent, PeopleComponent, ArchiveComponent, CreateComponent, EditComponent, ClientComponent,
    PrintComponent, SummaryComponent, UnsureComponent, DocumentsComponent, FormviewComponent]
})
export class AdminModule { }
