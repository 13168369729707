import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {AppConfig} from '../../../../config';
import {Formio} from 'formiojs';
import {FormioAuthService} from 'angular-formio/auth';
import {FormService} from '../../../shared/formio/form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import FormioUtils from 'formiojs/utils';
import swal from 'sweetalert2';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: [
    './documents.component.scss',
    './../../../../assets/css/modals.scss',
    './../../../../assets/css/global.scss'
  ]
})
export class DocumentsComponent implements OnInit {
  public params: any;
  public client: any = {
    data: {
      firstName: "...",
      surName: "...",
    }
  };
  
  constructor(
    public auth: FormioAuthService,
    public service: FormService,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private _location: Location
  ){}
  // this.src = `${AppConfig.appUrl}/documents`; // this was in the constructor
  public data = {
    submissions: [],
    submissionsFormKey: [],
    submissionsFormTitles: [],
    forms: {},
    documents: [],
    files: [],
  }
  public src = {
    create: `${AppConfig.appUrl}/documents/submission`,
    replace: `${AppConfig.appUrl}/documents/submission/`,
  }
  public closeModalId = '';
  public formSubmissions = {
    create:{
      data:{
        "caseId": "test",
        "questionKey": "test",
        "documentTitle": "",//"title of documents",
        "briefDescription": "", // description
        "filesandDocuments": []
      }
    },
    replace:{
    }
  }
  
  ngOnInit () { 
    (async (self) => {
      // store params
      self.params = self.route.snapshot.params;
      console.log( self.params )
      // get forms and files
      let forms = await self.getFormData();
      self.data.forms = forms;
      let files = await self.getFiles (self.params.caseId);
      this.sortAndStoreFiles(files);
      
      // gets the client case because we need the Id
      let clientCase = await self.getClientCase();
      let caseData = clientCase["data"];
      // get the user 
      self.client = await self.getClient(caseData.clientId);
      // for each form fetch the submission and find all the documents hidden as tags
      for ( let formIndex in forms ) {
        let form = forms[formIndex];
        
        if (caseData[form.name] !== undefined) { // if form is part of the case - some arnt but are we are reusing the fetch from the dash
          self.data.submissionsFormKey.push(form.name); //list the titles so we can loop in the view
          self.data.submissionsFormTitles.push(form.title);
          let caseId = caseData[form.name]._id;
          let submission = await self.getFormSubmission(form, caseId); // fetch the submission
          self.data.submissions[form.name] = submission; // store it
          self.findFiles(form); // find all the documents
        }
      }
      console.log( "self.data", self.data )
        console.log( "self.formSubmissions", self.formSubmissions )
    })(this);
  }

  /*
  * gets all the forms used
   */
  getFormData () { return new Promise((resolve, reject) => {
    this.service.getForms('&tags__in=dashboard').subscribe((forms) => {
      // console.log("forms",forms)
      resolve(forms);
    });
  }) }
  
  /*
  * get the clients case data
   */
  getClientCase () { return new Promise((resolve, reject) => {
  let clientCase = new Formio(`${AppConfig.appUrl}/case/submission/${this.params.caseId}`);
      clientCase.loadSubmission().then((submission) => {
        // console.log("clientCase", submission)
        resolve(submission);
      });
  }) }
  
  /*
  * get a submision from a from
   */
  getFormSubmission (form, caseId) { return new Promise((resolve, reject) => {
    let req = new Formio(`${AppConfig.appUrl}/${form.name}/submission/${caseId}`);
        req.loadSubmission().then((submission) => {
          resolve(submission);
        });
    }) }
    
    /*
    * gets all the files that match the case Id
     */
    getFiles ( caseId ) { return new Promise((resolve, reject) => {
      let req = new Formio(`${AppConfig.appUrl}/documents/submission`);
          req.loadSubmissions({
            params:{
               "data.caseId": caseId,
            }
          }).then((arrayOfFiles)=>{
              resolve(arrayOfFiles);
          });
    }) }
    

    sortAndStoreFiles (files) {
      this.data.files = []; //reset the files array
      // loop through each file and store them under their key
      for ( let fileIndex = 0; fileIndex < files.length; fileIndex++ ) {
        let file = files[fileIndex];
        if (this.data.files[file.data.questionKey] === undefined) {
          this.data.files[file.data.questionKey] = [];
        }
        this.data.files[file.data.questionKey].push(file);
      }
    }
    
    /*
    * find all the inputs that have a document as a tag - has a key that matches a document
     */
    
    findFiles (form) { 
      let allComps = FormioUtils.flattenComponents(form.components);
      for ( var compId in allComps ){
        let component = allComps[compId];
        if (component.tags && component.tags.includes('document')) {
          let subKey = component.key;
          // console.log("doc", form.name, component);
        if (this.data.documents[form.name] === undefined){
          this.data.documents[form.name] = [];
        }
        this.data.documents[form.name].push(component)
        }
      }
    }
  
  
  getKey (nameIndex) {
    return this.data.submissionsFormKey[nameIndex];
  }
  getTitle (nameIndex) {
    return this.data.submissionsFormTitles[nameIndex];
  }
  getDocuments (nameIndex){
    return this.data.documents[this.getKey(nameIndex)];
  }
  getDocument (nameIndex, docIndex) {
    return this.getDocuments(nameIndex)[docIndex];
  }
  /**
   * gets the title of the indovudual document set
   * @param String the name of the form
   * @param  docIndex key for value 
   * @return           the title for the group of documents
   */
  getDocumentTitle (nameIndex, docIndex) {
    return this.getDocument(nameIndex, docIndex)["tags"][2];
  }
  
  // gets the key of the document used to match it when uploaded to the filed
  getDocumentKey (nameIndex, docIndex) { 
    return this.getDocument(nameIndex, docIndex).key
  }
  
  // gets all the files for a doucment set
  getDocumentsFiles (nameIndex, docIndex) { 
    let files = this.data.files[this.getDocumentKey (nameIndex, docIndex)];
    // console.log(files)
    if (files !== undefined){
      return files;
    }
    return [];
  }
  
  // gest the file from this.data.files 
  getFile (nameIndex, docIndex, fileIndex) {
    return this.getDocumentsFiles (nameIndex, docIndex)[fileIndex];
  }
  
  /**
   * function gets the users profile and stores their information in the model
   * @param  clientId the clientId - which AIP wise is the clientProfileId
   * @return         null - will run async
   */
  getClient (clientId) {  return new Promise((resolve, reject) => { // where client id = clientProfileId
    let req = new Formio(`${AppConfig.appUrl}/clientprofile/submission/${clientId}`);
        req.loadSubmission().then((cleintProfile) => {
          resolve(cleintProfile);
        });
  } )}
  
  
//opens the create modal and sets all the required data
  openCreate (caseId, questionKey) {
    this.formSubmissions.create.data.documentTitle = "";
    this.formSubmissions.create.data.briefDescription = "";
    this.formSubmissions.create.data.caseId = caseId;
    this.formSubmissions.create.data.questionKey = questionKey;
    console.log("openCreate", this.formSubmissions.create);
    this.modalOpen("uploadFileModal");
  }
  
  //opens the replace modal and sets all the required data
  async openReplace ( fileId ) {
     let fileSubmission = await this.getFileSubmission(fileId);
     console.log("fileSubmission", fileId ,fileSubmission);
     this.formSubmissions.replace = fileSubmission;
      this.modalOpen("replaceFileModal");
  }
  
  /**
   * gets the dubmission of a file for form io 
   * @param  fileId the document submission id
   * @return        async fileSubmission 
   */
  getFileSubmission ( fileId ) { return new Promise((resolve, reject) => {
    let req = new Formio(this.setReplaceFileId(fileId));
        req.loadSubmission().then((fileSubmission)=>{
            resolve(fileSubmission);
        });
  }) }
  
  /**
   * set the src used for the replace form 
   * it also returns this url becuase it is used in the getFileSubmission() fn
   * @param  fileId the document submission id
   * @return        url for form io src
   */
  setReplaceFileId ( fileId ) {
    this.src.replace = `${AppConfig.appUrl}/documents/submission/${fileId}`;
    console.log(this.src.replace);
    return this.src.replace;
  }
  
  /*
    show modal function 
   */
  modalOpen (id) {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-close')
    document.getElementById(id).classList.remove('jm-modal-close')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-open')
    document.getElementById(id).classList.add('jm-modal-open')
    this.closeModalId = id // the close id for the modal
  }
  /*
    close modal function 
   */
  modalClose () {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-open')
    document.getElementById(this.closeModalId).classList.remove('jm-modal-open')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-close')
    document.getElementById(this.closeModalId).classList.add('jm-modal-close')
  }
  
  
  // refreshses file files and closes the model
  async afterSave () {
    this.modalClose();
    let files = await this.getFiles (this.params.caseId);
    this.sortAndStoreFiles(files);
  }
  
  // refreshses file files after remvoe
  async afterRemove () {
    let files = await this.getFiles (this.params.caseId);
    this.sortAndStoreFiles(files);
  }
  
  async removeFile ( fileId ) {
    //TO DO: do drop file and wait for it
    await this.afterRemove();
    console.log("done removeing");
  }

}
