// import {ViewEncapsulation, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
// import {Formio} from 'formiojs'
// import {ActivatedRoute, Router} from '@angular/router';
// import {FormioAppConfig, FormioComponent} from 'angular-formio';
// import {AppConfig} from '../../config';
// import FormioUtils from 'formiojs/utils';
// import {FormioAuthService} from 'angular-formio/auth';
// import {Location} from '@angular/common';
// 
// import {FormService} from '../shared/formio/form.service';

import {ViewEncapsulation, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Formio} from 'formiojs'
import {ActivatedRoute, Router} from '@angular/router';
import {FormioAppConfig, FormioComponent} from 'angular-formio';
import {AppConfig} from '../../config';
import FormioUtils from 'formiojs/utils';
import {FormioAuthService} from 'angular-formio/auth';
import {Location} from '@angular/common';

import {FormService} from '../shared/formio/form.service';



// @Component({
//   selector: 'app-formview',
//   templateUrl: './formview.component.html',
//   styleUrls: [
//     './formview.component.scss',
//     './../../assets/css/global.scss'
//   ],
//   encapsulation: ViewEncapsulation.None
// })
@Component({
  selector: 'app-formview',
  templateUrl: './formview.component.html',
  styleUrls: [
    './formview.component.scss',
    './../../assets/css/global.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class FormviewComponent implements OnInit {
  @ViewChild(FormioComponent) child: FormioComponent;
  constructor (
    public auth: FormioAuthService, public route: ActivatedRoute,
    public router: Router, public config: FormioAppConfig,
    private _location: Location, public service: FormService,
  ) {}

  public saveStates = {
    FULL_SAVED: 'All changes saved.',
    DRAFT_SAVED: 'All changes saved as draft.',
    SAVING: 'Saving changes.',
    CREATE: 'No changes saved.'
  }
  
  public params = this.route.snapshot.params;
  public formPannels = [];
  public rawPannels = [];
    
  public data: any = {
    submissions: [],
    submission:{},
    src: false,
    form: {
      name: "..."
    },
    autoSaveText: "...",
    clientCase:{
      data:{
        clientId:"",
      }
    },
    carousel: [],
    carouselIndex: 0,
    carouselCenterIndex: 0,
  }
  
  public carousel = {
    a: {
      title: "",
      num:"",
      parcent:0,
      isCurrent:false,
      link:"",
    },
    b: {
      title: "",
      num:"",
      parcent:0,
      isCurrent:false,
      link:"",
    },
    c: {
      title: "",
      num:"",
      parcent:0,
      isCurrent:false,
      link:"",
    }
  };
    
  /**

   */
 /**
  * -----------------------------------------------------------------------------
  * entry point 
  *- get all form data and stores it
  *- sets up all the data so that the UI works
  * -----------------------------------------------------------------------------
  */
  ngOnInit () { (async (self) => {
    // get all forms
    let forms = await self.getFormData();
    // get client case and submissions
    let clientCase = await self.getClientCase();
    self.data.clientCase = clientCase;
    for ( let formIndex in forms ) {
      let form = forms[formIndex];
      let submission = await self.getFormSubmission ( form, clientCase["_id"], self.getClientId() );
       self.data.submissions[form.name] = submission;
       if (form.name === self.params.formname){
          self.data.submission = submission; // set submission data
          self.data.src = `${AppConfig.appUrl}/${self.params.formname}`; // set src route and trigger refresh 
          self.data.form = form; //store the form we are working on
       }
    }
    // set the rest of the gui
    self.setSaveState( self.saveStates.FULL_SAVED );
    self.createFromList ( forms, self.data.submissions );
    // we now set up the links on the ready fn of the form
  
    console.log("done", self);   // all done
  })( this ) }
  
  /**
   * -----------------------------------------------------------------------------
   * form events 
   * -----------------------------------------------------------------------------
   */
  
  // sets the client id into the submision
  onPrepSubmission () {
    this.data.submission.data.clientId = this.getClientId();
  }
  
  /**
   * is called when the form is ready
    *- used to call refresh on the form comp
    *- sets the links the first time
  */
  async onFormReady () {
    await this.setLinks( this.data.form, this.data.submission );
    this.addOnScreenScrollEvent(this);
    this.formPannels[0].onScreen = true; // set the first one to true
  }
  
  /**
   * is called when the form is changed
    *- updates the side links 
    *- submits a draft or real submission
  */
  async onInputChange () {
    await this.setLinks( this.data.form, this.data.submission );
    this.updateLinks();
    // get new complete state
    let completedState = this.getCompleteState();
    this.data.submission.data.totalProgress = completedState;
    // save changs
    this.onSave();
  }
  
  /**
   * -----------------------------------------------------------------------------
   * functions
   * -----------------------------------------------------------------------------
   */
  
  // gets the client Id from the model
  getClientId () {
    return this.data.clientCase.data.clientId;
  }

  // gets all the forms in the dash board
  getFormData () { return new Promise((resolve, reject) => {
    this.service.getForms('&tags__in=dashboard').subscribe((forms) => {
      // console.log("forms",forms)
      resolve(forms);
    });
  }) }

  /**
   * gets the submision for a form
   * @param  form     form to get the submission for
   * @param  caseId   the clients case ID
   * @param  clientId the clients ID
   * @return          submission
   */
  getFormSubmission (form, caseId, clientId) { return new Promise((resolve, reject) => {
      // console.log("using caseID", caseId)
      let req = new Formio(`${AppConfig.appUrl}/${form.name}/submission/`);
          req.loadSubmissions({
            params:{
               "data.caseId": caseId,
            }
          }).then((submission) => {
            // console.log("submission",submission[0])
            if (submission.length > 0){
              resolve(submission[0]); // just resolve with the first one 
            } else {
              resolve({data:{
                caseId: caseId,
                clientId: clientId,
              }});
            }
          });
  }) }
    
  
  // gets the curent clients case
  getClientCase () { return new Promise((resolve, reject) => {
  let clientCase = new Formio(`${AppConfig.appUrl}/case/submission/${this.params.caseId}`);
      clientCase.loadSubmission().then((submission) => {
        // console.log("clientCase", submission)
        resolve(submission);
      });
  }) }
  
  // used to update the save state in the UI
  setSaveState ( newState ) {
    this.data.autoSaveText = newState
  }  
  
  onSave () {
    // get the complet state and update the submision
    let completedState = this.getCompleteState();
    this.data.submission.data.totalProgress = completedState;
    // set the submision mode
    this.setSaveState( this.saveStates.SAVING );
    if ( completedState == 100 ) { //Submitted
        this.data.submission.state = 'submitted'
    } else { //draft
        this.data.submission.state = 'draft'
    }
    // generate the url to submit to 
    let formName = this.data.form.name;
    let submissionId = this.data.submission._id;
    let url = '';
    if ( submissionId === undefined ) { // handle no submision 
      url = `${AppConfig.appUrl}/${formName}/submission`;
    } else {
      url = `${AppConfig.appUrl}/${formName}/submission/${submissionId}`;
    }
    // submit form
    let form = new Formio(url);
        form.saveSubmission( this.data.submission ).then((sub) => {
          if ( completedState == 100 ) {
            this.setSaveState( this.saveStates.FULL_SAVED );
          } else {
            this.setSaveState( this.saveStates.DRAFT_SAVED );
          }
        });
  }
    
  // re scans all the inputs in the pannels and updates the side bar
  updateLinks () {
    let pannels = this.formPannels;
    let rPannels = this.rawPannels;
    for (let pannelId in pannels){ 
      let pannel = pannels[pannelId];
      let rPannel = rPannels[pannelId];
      let inputs = this.getPannelInputs(rPannel.components);
      this.formPannels[pannelId].completed = this.getPannelCompleteState(inputs, this.data.submission);
    }
  }
  
  /**
   * sets up the lins on the side bar
   * @param  form       the curent form data
   * @param  submission the current submission data
   */
  setLinks ( form, submission ){ return new Promise((resolve, reject) => {
    // re-set state so we don't leak memory
    this.formPannels = []; 
    this.rawPannels = [];
    var pannels = FormioUtils.findComponents(form.components, {
         'type': 'panel',
         'hidden': false,
       });
       // console.log(pannels, "pannels");
      for (let pannelId in pannels){ let pannel = pannels[pannelId];
        let inputs = this.getPannelInputs(pannel.components);
        // console.log("pannel",pannel);
        let link = {
          title: pannel.title,
          onScreen: false,
          inputs: inputs,
          id: "", // we can't set it here becuase formio json is missing it during this time
          completed: this.getPannelCompleteState( inputs, submission ),
        }
        this.formPannels.push(link);
      }
      this.rawPannels = pannels;
    resolve("done");
  }) }
  
  // finds all comps that are an input, required and not hidden
  getPannelInputs (components) {
    var inputs = FormioUtils.findComponents(components, {
      'input': true,
      'hidden': false,
      'validate.required': true, 
    });
    return inputs;
  }
  
  // calculates the complete ness of the form based on the pannels
  getCompleteState () {
    let numOutOf = this.formPannels.length;
    let numCompleted = 0;
    let pannels = this.formPannels;
    for (let pannelId in pannels){ let pannel = pannels[pannelId];
      if ( pannel.completed ){
        numCompleted += 1;
      }
    }
    return Math.ceil( ( 100 / numOutOf ) * numCompleted );
  }
  
  //looks at all the inputs in a pannel to check the complete state
  getPannelCompleteState (inputs, submission) {
    // set up all filled in ok var to be returned
    let isAllFilledIn = true; 
    // for all inputs in pannel check values
    for (let inputId in inputs){ let input = inputs[inputId];
      // if we are a input matrix hande it 
      if ( input.questions !== undefined ) { 
        let value = FormioUtils.getValue(submission, input.key); // get value 
        let questions = input.questions;
        for (let questionIndex in questions){ let question = questions[questionIndex];
          let val = value[question.value]; // get value in array
          if (this.valueIsSet(value) === false){ // if is missing
            isAllFilledIn = false; // update complete state to false
          }
        }
      } else { // we are just a normal input
        let value = FormioUtils.getValue(submission, input.key) // get value
        if ( this.valueIsSet(value) === false ) { // if is missing
          isAllFilledIn = false;  // update complete state to false
        }
      }
    }
    return isAllFilledIn; // return filled in state
  }

  /**
   * checks to see if a value from submission contains avalue that isn't empty
   * @param  value var or empty 
   * @return       true/false
   */
  valueIsSet (value) {
    if (value === undefined || value === "" || value === null){
      return false
    }
    return true
  }
  
  /**
   * adds a scroll event handler that moves the links along on the screen 
   * @param  self [description]
   */
  addOnScreenScrollEvent(self){ if ( true ){
    const scrollBox =  window; // document.getElementById('jm-form-container');
    
    scrollBox.addEventListener('scroll', function (){
      for (let pannelId in self.rawPannels) { 
        // get objs into short form 
        let rPannel = self.rawPannels[pannelId];
        let pannel = self.formPannels[pannelId];
        //get windo top & bottom
        let screenBotm = window.pageYOffset + window.screen.height;//scrollBox.scrollTop + window.screen.height;
        let screenTop = window.pageYOffset; //scrollBox.scrollTop;
        // get the dom el and height
        let dom = document.getElementById( rPannel.id ); 
        // console.log(rPannel)
        if (dom !== undefined){ // if id is there then do stuff
          let elementTop = dom.offsetTop; 
          if ( elementTop >= screenTop && elementTop <= screenBotm ) { // if on screen
            if (pannel.onScreen !== true){ // if not already true
              self.clearLinksOnScreen();
              pannel.onScreen = true;
            }
          }
        }
      }
    });
    
  }}
  
  /**
   * is used to clear the links onScreen var to false before setting a 
   * new one to true
   */
  clearLinksOnScreen () {
    for (let pannelId in this.formPannels) { let pannel = this.formPannels[pannelId];
          pannel.onScreen = false;
    }
  }
  
  /**
   * scrols to a on the page
   * @param  id          the id of the dom to scroll to
   * @param  pannelIndex the index to update the link.onScreen
   */
  scrollToId (id, pannelIndex) {
    let dom = document.getElementById(id);
      if ( dom !== null ) { // prevents a crash on null
        dom.scrollIntoView();
        this.clearLinksOnScreen();
        let pannel = this.formPannels[pannelIndex];
            pannel.onScreen = true;
      }
  }
  
  //clean up events when pages is navigated away from
  ngOnDestroy() {
    $(window).off('resize scroll');
  }
/**
 * -----------------------------------------------------------------------------
 * code for carousel
 * -----------------------------------------------------------------------------
 */
 // build the data set used for the carousel
   createFromList ( forms, submisions ) {   
     //carousel 
       for (let fli = 0; fli < forms.length; fli ++) { // for each form build model
         let form = forms[fli];
         let newItem = {
           title: form.title,
           num: fli + 1,
           parcent: 0 + submisions[form.name].data.totalProgress,
           isCurrent: false,
           link: this.makeLink( form.name ),
         }
         // if is curent form than make a note of it and set the pos
         if (form.name === this.params.formname) {
           newItem.isCurrent = true;
           this.data.carouselCenterIndex = fli
         }
         // add item to list
         this.data.carousel.push( newItem );
       }
       // set view modal
       this.updateFormList ( this.data.carouselCenterIndex );
   }
   
   // make a link to a form
   makeLink ( formName, submissionId = 'link' ) {
     //return `${this.params.adminId}/matter/${this.params.matterId}/case/${this.params.caseId}/view/${formName}/${submissionId}`;
     return `${this.params.caseId}/view/${formName}/${submissionId}`;
   
   }
   
   // update the cars on the page with a new index
   updateFormList ( index ) {
     let carousel = this.data.carousel;
     if (index === 0) { // in the case this is the 0 item in the list
       this.data.carouselCenterIndex = 1; // set index to 1 so that two click when going in oposit direction
       this.carousel.a = carousel[0];
       this.carousel.b = carousel[1];
       this.carousel.c = carousel[2];
     }
    if (index > 0 && index <= carousel.length - 2) { // in the case this is in the middle
       this.carousel.a = carousel[index - 1];
       this.carousel.b = carousel[index];
       this.carousel.c = carousel[index + 1];
     }
     if (index === carousel.length - 1) { // in the case this is the last
       this.carousel.a = carousel[index - 2];
       this.carousel.b = carousel[index - 1];
       this.carousel.c = carousel[index];
       this.data.carouselCenterIndex -= 1; // set index to -1 so that two click when going in oposit direction
     }
   }
   
   // add one from index and then repaint model data
   formListNext (){ 
     if ( this.data.carouselCenterIndex < this.data.carousel.length ) {
       this.data.carouselCenterIndex += 1;
       this.updateFormList( this.data.carouselCenterIndex )
     }
   }
   
   // take one from index and then repaint model data
   formListBack (){ 
     if ( this.data.carouselCenterIndex > 0 ) {
       this.data.carouselCenterIndex -= 1;
       this.updateFormList ( this.data.carouselCenterIndex );
     }
   }
   // navigate to new page
   goToForm (letter) {
     let link = this.carousel[letter].link;
     this.router.navigateByUrl(link);
     location.reload(); // need to refresh the page as angular isn't smart enough to know that the page has been refreshed with new params
   }
  // view getter fns
  cardIsCurrent ( letter ) {
    return this.carousel[letter].isCurrent;
  } 
   
  cardNumber ( letter ) {
    return this.carousel[letter].num;
  }
  
  cardTitle ( letter ) {
    return this.carousel[`${letter}`].title
  }
  
  cardParcent ( letter ) {
    let textParcent = this.carousel[`${letter}`].parcent;
    return this.toParcent( textParcent );
  }
  
  // calc parcent - fixes Nan issue
  toParcent(parcent) {
    if (!isNaN(parcent)) {
      const result = Math.floor(parcent);
      if (result < 100) {
        return ' ' + result;
      } else {
        return result;
      }
    } else {
      return '  0.0'
    }
  } 
}



