import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  // moduleId: module.id,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Input() components: any;
  public menuItems: any[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private _location: Location) {
  }
  backClicked() {
    this._location.back();
  }
  scroll(id) {
    const el = document.getElementById(id);
    el.scrollIntoView();
  }
  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

}
