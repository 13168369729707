import { Component, OnInit } from '@angular/core';
import {AppConfig} from '../../../../config';
import {Formio} from 'formiojs';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {FormioAuthService} from 'angular-formio/auth';
import FormioUtils from 'formiojs/utils';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  constructor(
    protected location: Location,
    private _router: Router,
    private _auth: FormioAuthService,
    public route: ActivatedRoute,
   ) {}
  
  public saveStates = {
    FULL_SAVED: 'All changes saved.',
    DRAFT_SAVED: 'All changes saved as draft.',
    SAVING: 'Saving changes.',
    CREATE: 'No changes saved.'
  }
  
  public params = this.route.snapshot.params;
  public formPannels = [];
  public rawPannels = [];
    
  public data: any = {
    submissions: [],
    submission:{
      data:{}
    },
    src: `${AppConfig.appUrl}/clientprofile`,
    form: {
      name: "..."
    },
    autoSaveText: "Auto save unavailable.",
    // clientCase:{
    //   data:{
    //     clientId:"",
    //   }
    // },
    // carousel: [],
    // carouselIndex: 0,
    // carouselCenterIndex: 0,
  }

  /**
   * -----------------------------------------------------------------------------
   * entry point 
   *- get the form data and store it
   *- sets up all the data so that the UI works
   * -----------------------------------------------------------------------------
   */
  ngOnInit() { (async (self) => {
    let form = await self.getFormData();
    self.data.form = form;
    self.data.src =`${AppConfig.appUrl}/clientprofile`;
    self.setLinks ( form, this.data.submission);
    console.log(form)
  })( this ) }
   
   
   
  /**
   * -----------------------------------------------------------------------------
   * form events 
   * -----------------------------------------------------------------------------
   */
  
  // sets the client id into the submision
  onPrepSubmission () {
    // this.data.submission.data.clientId = this.getClientId();
  }
  
  /**
   * is called when the form is ready
    *- used to call refresh on the form comp
    *- sets the links the first time
  */
  async onFormReady () {
    await this.setLinks( this.data.form, this.data.submission );
    this.addOnScreenScrollEvent(this);
    this.formPannels[0].onScreen = true; // set the first one to true
  }
  
  /**
   * is called when the form is changed
    *- updates the side links 
  */
  async onInputChange () {
    await this.setLinks( this.data.form, this.data.submission );
    this.updateLinks();
    // get new complete state
    let completedState = this.getCompleteState();
    this.data.submission.data.totalProgress = completedState;
    // save changs
    // this.onSave();
  } 
  
  
   
  getFormData () { return new Promise((resolve, reject) => {
    let form = new Formio(`${AppConfig.appUrl}/clientprofile`);
        form.loadForm().then(form => {
          resolve(form);
        });
  }) }
   
  // re scans all the inputs in the pannels and updates the side bar
  updateLinks () {
    let pannels = this.formPannels;
    let rPannels = this.rawPannels;
    for (let pannelId in pannels){ 
      let pannel = pannels[pannelId];
      let rPannel = rPannels[pannelId];
      let inputs = this.getPannelInputs(rPannel.components);
      this.formPannels[pannelId].completed = this.getPannelCompleteState(inputs, this.data.submission);
    }
  }
  
  /**
   * sets up the lins on the side bar
   * @param  form       the curent form data
   * @param  submission the current submission data
   */
  setLinks ( form, submission ){ return new Promise((resolve, reject) => {
    // re-set state so we don't leak memory
    this.formPannels = []; 
    this.rawPannels = [];
    var pannels = FormioUtils.findComponents(form.components, {
        'type': 'panel',
        'hidden': false,
       });
       // console.log(pannels, "pannels");
      for (let pannelId in pannels){ let pannel = pannels[pannelId];
        let inputs = this.getPannelInputs(pannel.components);
        // console.log("pannel",pannel);
        let link = {
          title: pannel.title,
          onScreen: false,
          inputs: inputs,
          id: "", // we can't set it here becuase formio json is missing it during this time
          completed: this.getPannelCompleteState( inputs, submission ),
        }
        this.formPannels.push(link);
      }
      this.rawPannels = pannels;
    resolve("done");
  }) }
  
  // finds all comps that are an input, required and not hidden
  getPannelInputs (components) {
    var inputs = FormioUtils.findComponents(components, {
      'input': true,
      'hidden': false,
      'validate.required': true, 
    });
    return inputs;
  }
  
  // calculates the complete ness of the form based on the pannels
  getCompleteState () {
    let numOutOf = this.formPannels.length;
    let numCompleted = 0;
    let pannels = this.formPannels;
    for (let pannelId in pannels){ let pannel = pannels[pannelId];
      if ( pannel.completed ){
        numCompleted += 1;
      }
    }
    return Math.ceil( ( 100 / numOutOf ) * numCompleted );
  }
  
  //looks at all the inputs in a pannel to check the complete state
  getPannelCompleteState (inputs, submission) {
    // set up all filled in ok var to be returned
    let isAllFilledIn = true; 
    // for all inputs in pannel check values
    for (let inputId in inputs){ let input = inputs[inputId];
      // if we are a input matrix hande it 
      if ( input.questions !== undefined ) { 
        let value = FormioUtils.getValue(submission, input.key); // get value 
        let questions = input.questions;
        for (let questionIndex in questions){ let question = questions[questionIndex];
          let val = value[question.value]; // get value in array
          if (this.valueIsSet(value) === false){ // if is missing
            isAllFilledIn = false; // update complete state to false
          }
        }
      } else { // we are just a normal input
        let value = FormioUtils.getValue(submission, input.key) // get value
        if ( this.valueIsSet(value) === false ) { // if is missing
          isAllFilledIn = false;  // update complete state to false
        }
      }
    }
    return isAllFilledIn; // return filled in state
  }

  /**
   * checks to see if a value from submission contains avalue that isn't empty
   * @param  value var or empty 
   * @return       true/false
   */
  valueIsSet (value) {
    if (value === undefined || value === "" || value === null){
      return false
    }
    return true
  } 
  
  /**
   * adds a scroll event handler that moves the links along on the screen 
   * @param  self [description]
   */
  addOnScreenScrollEvent(self){ if ( false ){
    const scrollBox =  window; // document.getElementById('jm-form-container');
    
    scrollBox.addEventListener('scroll', function (){
      for (let pannelId in self.rawPannels) { 
        // get objs into short form 
        let rPannel = self.rawPannels[pannelId];
        let pannel = self.formPannels[pannelId];
        //get windo top & bottom
        let screenBotm = window.pageYOffset + window.screen.height;//scrollBox.scrollTop + window.screen.height;
        let screenTop = window.pageYOffset; //scrollBox.scrollTop;
        // get the dom el and height
        let dom = document.getElementById( rPannel.id ); 
        // console.log(rPannel)
        if (dom !== undefined){ // if id is there then do stuff
          let elementTop = dom.offsetTop; 
          if ( elementTop >= screenTop && elementTop <= screenBotm ) { // if on screen
            if (pannel.onScreen !== true){ // if not already true
              self.clearLinksOnScreen();
              pannel.onScreen = true;
            }
          }
        }
      }
    });
    
  }}
  
  /**
   * is used to clear the links onScreen var to false before setting a 
   * new one to true
   */
  clearLinksOnScreen () {
    for (let pannelId in this.formPannels) { let pannel = this.formPannels[pannelId];
          pannel.onScreen = false;
    }
  }
  /**
   * scrols to a on the page
   * @param  id          the id of the dom to scroll to
   * @param  pannelIndex the index to update the link.onScreen
   */
  scrollToId (id, pannelIndex) {
    let dom = document.getElementById(id);
      if ( dom !== null ) { // prevents a crash on null
        dom.scrollIntoView();
        this.clearLinksOnScreen();
        let pannel = this.formPannels[pannelIndex];
            pannel.onScreen = true;
      }
  }

  onSave () {
    this._router.navigate(['../'], { relativeTo: this.route });
  }
  

}
