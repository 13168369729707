import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {FormioAuthService} from 'angular-formio/auth';

@Injectable()
export class AuthClientService implements CanActivate {

  constructor(private _auth: FormioAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._auth.ready.then(() => {
       return this._auth.authenticated && this._auth.is.client;
    })
  }
}
