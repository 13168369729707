import { Injectable } from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';

@Injectable()
export class StorageService {

  constructor(protected localStorage: LocalStorage) { }

  getToken() {
    return this.localStorage.getItem('formioToken');
  }

}
