import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {AppConfig} from '../../../../config';
import {Formio} from 'formiojs';
import {FormioAuthService} from 'angular-formio/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../../shared/formio/form.service';
import FormioUtils from 'formiojs/utils';

@Component({
  selector: 'app-unsure',
  templateUrl: './unsure.component.html',
  styleUrls: ['./unsure.component.scss']
})
export class UnsureComponent implements OnInit {
  public formio: any;
  public totalProgress: any = 0;
  public utils = FormioUtils;
  public client: any = {
    data: {
      firstName: "...",
      surName: "...",
    }
  };
  public clientCase: any;
  public currentCase: any;
  public docs: any;
  public params: any;
  public forms: any[] = [];
  constructor(
    public _auth: FormioAuthService,
    public _router: Router,
    public _route: ActivatedRoute,
    public _service: FormService,
    private _location: Location) {
  }
  backClicked() {
    this._location.back();
  }

// Get Forms
  getForms() {
    this._service.getForms('&tags__in=dashboard').subscribe((forms) => {
      this.getCaseId(forms);
    })
  }


  /**
   * function gets the users profile and stores their information in the model
   * @param  clientId the clientId - which AIP wise is the clientProfileId
   * @return         null - will run async
   */
  getClient (clientId) {  // where client id = clientProfileId
    let req = new Formio(`${AppConfig.appUrl}/clientprofile/submission/${clientId}`);
        req.loadSubmission().then((cleintProfile) => {
          this.client = cleintProfile
        });
  }
// Get Case ID
  getCaseId(forms) {
    this._route.params.subscribe((params) => {
      console.log(params);
      this.params = params;
      this.clientCase = new Formio(`${AppConfig.appUrl}/case/submission/${params.caseId}`);
      this.clientCase.loadSubmission().then((submission) => {
        this.currentCase = submission;
        this.getClient(this.currentCase.data.clientId)
        this.getData(forms);
      })
    })
  }
// Get Case Data
  getData(forms) {
    // const partner = ` ${this.currentCase.data.qualifyingQuestions.data.qualPartnerFirstName} ${this.currentCase.data.qualifyingQuestions.data.qualPartnerSurname}`;
    forms.forEach((form) => {
      FormioUtils.eachComponent(form.components, (component) => {
        if (component.type === 'htmlelement') {

          // component.content = component.content.replace(/ *\[FORMER PARTNER]*/g, partner);
        }
        if (component.tooltip) {
          // component.tooltip = component.tooltip.replace(/ *\[FORMER PARTNER]*/g, partner);
        }
        if (component.type === 'survey') {
          component.questions.forEach((question) => {
            if (question.label) {
              // question.label = question.label.replace(/ *\[FORMER PARTNER]*/g, partner);
            }
          })
        }
        // const label = component.label.replace(/ *\[FORMER PARTNER]*/g, partner);
        // component.label = label;
      });
      form.components.forEach((panel) => {
        if (panel.components) {
          panel.components.forEach((component) => {
            if (component.legend) {
              // const legend = component.legend.replace(/ *\[FORMER PARTNER]*/g, partner);
              // component.legend = legend;
            }
          })
        }
      });
      if (this.currentCase.data[form.name]) {
        const clientCase = new Formio(`${AppConfig.appUrl}/${form.name}/submission/${this.currentCase.data[form.name]._id}`)
          .loadSubmission().then((submission) => {
            let unsure = null;
            const questions = [];
            if (submission) {
              for (const key in submission.data) {
                if (submission.data[key] === 'unsure') {
                  form.submissionData = submission;
                  unsure = form;
                  questions.push(key);
                }
              }
            }
            if (unsure) {
              this.forms.push(this.getQuestions(form, questions));
            }
          });
      }
    });
  }
// Get Questions
  getQuestions(form, questions) {
    form.questions = [];
    this.utils.eachComponent(form.components, (component) => {
      if (questions.indexOf(component.key) !== -1) {
        form.questions.push(component.label);
      }
    })
    return form;
  }
  ngOnInit() {
    this.getForms();
  }

}
