
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import {AppConfig, AuthConfig} from '../config';
import {FormioAppConfig, FormioModule} from 'angular-formio';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import {FormioAuthConfig, FormioAuthService} from 'angular-formio/auth';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';

import {AuthModule} from './auth/auth.module';
import {ClientModule} from './client/client.module';
import * as $ from 'jquery';
import {StorageService} from './shared/storage/storage.service';
import {NgxStripeModule} from 'ngx-stripe';
import { ErrorComponent } from './error/error.component';
import {AdminModule} from './admin/admin.module';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {SAdminModule} from './s-admin/s-admin.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthAdminService} from './shared/auth/auth-admin.service';
import {AuthClientService} from './shared/auth/auth-client.service';

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    ErrorComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    ClientModule,
    AdminModule,
    SAdminModule,
    FormioModule,
    FilterPipeModule,
    NgbModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_kBaVWKEBXwXZsib3vFTTQQtE'),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthService,
    FormioAuthService,
    StorageService,
    AuthGuard,
    AuthAdminService,
    AuthClientService,
    {provide: FormioAppConfig, useValue: AppConfig},
    {provide: FormioAuthConfig, useValue: AuthConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
