import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpadminComponent } from './spadmin/spadmin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {AdminRoutingModule} from './s-admin-routing.module';
import {FormioModule} from 'angular-formio';
import {SharedModule} from '../shared/shared.module';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    FormioModule,
    FilterPipeModule,
    FormsModule,
  ],
  declarations: [SpadminComponent, DashboardComponent]
})
export class SAdminModule { }
