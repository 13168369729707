// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  stripeKey: 'pk_test_kBaVWKEBXwXZsib3vFTTQQtE',
  master: '5be21877634fb3dd33fb7fde',
  live: '5bdf8bbf3383d7462197ef27',
  // tenantServer: 'form.io'
  tenantServer: 'http://enterprise-app.legallogix.com'
};
