import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
// import {Home_ROUTES} from './shared/routes/home.routes';
import {Auth_ROUTES} from './shared/routes/auth.routes';
import {Client_ROUTES} from './shared/routes/client.routes';
import {Profile_ROUTES} from './shared/routes/profile.routes';
import {Case_ROUTES} from './shared/routes/case.routes';
import {ErrorComponent} from './error/error.component';
import {Admin_ROUTES} from './shared/routes/admin.routes';
import {Sadmin_ROUTES} from './shared/routes/s-admin.routes';
import {AuthAdminService} from './shared/auth/auth-admin.service';
import {AuthClientService} from './shared/auth/auth-client.service';

const appRoutes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  // { path: '', component: ContentLayoutComponent, data: { title: 'Home' }, children: Home_ROUTES},
  { path: '', component: ContentLayoutComponent, data: { title: 'Authorization' }, children: Auth_ROUTES },
  { path: '', component: ContentLayoutComponent, data: { title: 'Client Dashboard' }, children: Client_ROUTES, canActivate: [AuthGuard, AuthClientService] },
  { path: '', component: ContentLayoutComponent, data: { title: 'Admin Dashboard' }, children: Admin_ROUTES, canActivate: [AuthGuard, AuthAdminService] },
  { path: '', component: ContentLayoutComponent, data: { title: 'Super Admin Dashboard' }, children: Sadmin_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'Profile' }, children: Profile_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'Case Workflow' }, children: Case_ROUTES, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
