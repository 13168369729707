import {ViewEncapsulation, Component, OnInit} from '@angular/core';
import {FormioAuthService} from 'angular-formio/auth';
import {ActivatedRoute, Router} from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [
    './navbar.component.scss',
    './../../../assets/css/global.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class NavbarComponent implements OnInit{
  public letters = '';
  constructor(
    public auth: FormioAuthService,     
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  openProfilePage () {
    let id = this.auth.user._id
    this.router.navigateByUrl(`/user/profile`);
  }
  
  ngOnInit() {
    this.auth.ready.then(() => {
      if ( this.auth.is.solicitor || this.auth.is.administrator ) {
        this.letters = 
        this.auth.user.data.solicitorProfileResourceSolicitorFirstNameText.charAt(0) +
        this.auth.user.data.solicitorProfileResourceSolicitorLastNameEmail.charAt(0);
      } else if ( this.auth.is.client ) {
        this.letters = this.auth.user.data.firstName.charAt(0) + this.auth.user.data.surName.charAt(0);
      } else {
        this.letters = "NA"
      }
      this.letters = this.letters.toUpperCase();
    })
  }
}
