import { Component } from '@angular/core';


@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
  styleUrls: [
    './full-layout.component.scss',
    './../../../assets/css/global.scss'
  ]
})

export class FullLayoutComponent {

}
