import {ViewEncapsulation, Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AppConfig} from '../../../../config';
import {ActivatedRoute, Router} from '@angular/router';
import {FormioAuthService} from 'angular-formio/auth';
import {FormService} from '../../../shared/formio/form.service';
import {Formio} from 'formiojs';



@Component({
  selector: 'app-admin-summary',
  templateUrl: './summary.component.html',
  styleUrls: [
    './summary.component.scss',
    './../../../../assets/css/global.scss'
  ],
  encapsulation:ViewEncapsulation.None
})
export class SummaryComponent implements OnInit {
  public formio: any;
  public totalProgress: any = 0;
  public client: any;
  public clientCase: any;
  public currentCase: any;
  public questions: any[] = [];
  public printComponents: any = {};
  public docs: any;
  public currentDate: any;
  public forms: any[] = [];
  constructor(
    public auth: FormioAuthService,
    public router: Router,
    public route: ActivatedRoute,
    public service: FormService,
    private _location: Location) {
  }
  backClicked() {
    this._location.back();
  }

  downloadBrief() {
    window.print();
  }


  // Get Forms
  getForms() {
    const form = new Formio(`${AppConfig.appUrl}/summary`);
    form.loadForm().then((brief) => {
      this.currentDate = new Date();
      this.service.getForms('&tags__in=dashboard').subscribe((forms) => {
        this.getCaseId(forms, brief);
      });
    })
  }

  // Get Case ID
  getCaseId(forms, brief) {
    if (this.route.snapshot.params.caseId) {
      this.clientCase = new Formio(`${AppConfig.appUrl}/case/submission/${this.route.snapshot.params.caseId}`);
      this.clientCase.loadSubmission().then((submission) => {
        // Check Valid Token if not redirect.
        this.currentCase = submission;
        this.getData(forms, brief);
      })
    }
  }
  // Get Case Data
  getData(forms, brief) {
    // console.log(brief);
    // console.log(brief.components);
    brief.components.forEach(component => {
      if (component.components) {
        const group = {section: {}, subsection: []};
        group.section = component;
        component.components.forEach((subsections, i) => {
          group.subsection.push({group: subsections, questions: [], answers: {}});
          if ((subsections.components && subsections.components.length > 0) || (subsections.rows && subsections.rows.length > 0)) {
            if (subsections.type === 'table') {
              subsections.rows.forEach((r) => {
                group.subsection[i].questions.push(r)
              })
            } else {
              // console.log(subsections);
              subsections.components.forEach(q => {
                group.subsection[i].questions.push(q)
              });
            }
          } else {
            if (subsections.type === 'htmlelement' && subsections.tags.includes('subtitle')) {
              group.subsection[i].title = subsections.content
            }
          }
        });
        this.questions.push(group);
      }
    })
    forms.forEach((form) => {
      if (this.currentCase.data[form.name]) {
        const clientCase = new Formio(`${AppConfig.appUrl}/${form.name}/submission/${this.currentCase.data[form.name]._id}`)
        clientCase.loadSubmission().then((submission) => {
          this.questions.forEach(question => {
            question.subsection.forEach((sub) => {
              if (sub.group && question.section.tags.indexOf(form.name) !== -1) {
                for (const key in submission.data) {
                  if (typeof submission.data[key] === 'string') {
                    sub.answers[key] = submission.data[key] ? submission.data[key].replace(/([A-Z])/g, ' $1')
                      .replace(/^./, (str) => { return str.toUpperCase(); }) : '';
                  }
                  if (Array.isArray(submission.data[key])) {
                    // console.log(submission.data[key]);
                    submission.data[key].forEach((ans, i) => {
                      if (typeof ans === 'string') {
                        if (ans) {
                          sub.answers[key] += (submission.data[key].length - 1 === i || submission.data[key].length === 1) ?
                            `${ans[key]} ` : `${ans[key]}, ` ;
                        }
                      } else {
                        for (const k in ans) {
                          if (typeof ans[k] === 'number') {
                            if (!sub.answers[k]) {
                              sub.answers[k] = 0;
                            }
                            sub.answers[k] = sub.answers[k] + ans[k];
                          }
                          if (Array.isArray(sub.answers[k])) {
                            sub.answers[k].push(ans[k])
                          } else if (typeof ans[k] === 'string') {
                            sub.answers[k] = [];
                            sub.answers[k].push(ans[k]);
                          }
                        }
                      }
                    })
                  } else if (typeof submission.data[key] === 'object') {
                    for (const matrix in submission.data[key]) {
                      if (typeof submission.data[key][matrix] === 'boolean') {
                        submission.data[key][matrix] ?  sub.answers[matrix] = 'Yes' : sub.answers[matrix] = 'No';
                      } else {
                        sub.answers[matrix] = submission.data[key][matrix].replace(/([A-Z])/g, ' $1')
                          .replace(/^./, (str) => { return str.toUpperCase(); });
                      }

                    }
                  }
                  if (submission.data[key].formatted_address) {
                    sub.answers[key] = submission.data[key].formatted_address ? submission.data[key].formatted_address : '';
                  } else {
                    sub.answers[key] =  submission.data[key] ? submission.data[key] : '';
                  }
                }
              }
            })
          })
        });
        this.forms.push(form);
      } else {
        this.forms.push(form);
      }
    });
  }
  checkTags(components, sub) {
    let response;
    if (components.components.length > 0) {
      components.components.forEach((res) => {
        if (res.tags.length > 0 && res.tags.includes('label')) {
          response = res.content;
        }
        if (res.tags.length > 0 && res.tags.includes('text')) {
          let str = '';
          res.tags.forEach((tag, i) => {
            if (i > 0) {
              if (res.tags[1] === 'array' && i > 1 && res.tags.length > 2 && Array.isArray(sub.answers[tag])) {
                // str = `<table><tbody>`;
                sub.answers[tag].forEach((value) => {
                  // str += `<tr><td>${value}</td><tr></tr>`
                  str += `${value}<br> `
                });
                // str += `</tbody></table>`;
              } else {
                str += `${sub.answers[tag] === 'undefined' || sub.answers[tag] === undefined || sub.answers[tag] === '' ? '' : sub.answers[tag]} `;
              }
            }
          })
          response = str;
        }
        if (res.tags.length > 0 && res.tags.includes('calc')) {
          let calc = '';
          let parts = [];
          if (res.tags.includes('array') && res.tags.length > 2) {
            if (Array.isArray(sub.answers[res.tags[2]])) {
              // console.log(sub.answers[res.tags[2]]);
            }
          } else {
            res.tags.forEach((tag, i) => {
              if (i > 0) {
                parts = tag.split(':');
                parts.forEach((key) => {
                  if (['+', '-', '*', '/', '(', ')', '[', ']', '400', '300', '100', '500'].indexOf(key) === -1) {
                    if(key === 'clientAtCommClientCaseRel_ClientAtCommInstitutionAccountBalanceCurrency') {
                      // console.log(sub.answers);
                      // console.log(key);
                      // console.log(sub.answers[key]);
                    }
                    calc += `${sub.answers[key] || 0} `;
                  } else {
                    calc += key || '';
                  }
                });
              }
            });
          }
          return response = eval(calc);
        }
      })
    }
    return response;
  }

  ngOnInit() {
    this.getForms();
  }


}
