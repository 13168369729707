import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppConfig, result, Forms} from '../../../config';
import {Formio} from 'formiojs';
import {environment} from '../../../environments/environment';
import {FormioAuthService} from 'angular-formio/auth';
import {Form} from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  tenants: any[] = [];
  status: any[] = [];
  formio: any;
  stage: any;
  remote: any;
  submission: any = {data: {}};
  resource: string;
  params: any;
  src: string;
  project: any;
  master: any;
  editsrc: string;
  constructor(private modalService: NgbModal) { }
  openModal(template) {
    this.src = `${environment.tenantServer}/${this.project.name}/${this.master.resources.tenants.name}`;
    this.modalService.open(template, { size: 'lg' });
  }
  openEdit(template, id) {
    this.editsrc = `${environment.tenantServer}/${this.project.name}/${this.master.resources.tenants.name}/${id}`;
    this.modalService.open(template, { size: 'lg' });
  }
  getProject() {
    Formio.loadProjects({
      params: {
        '_id': environment.master
      }
    }).then(project => {
      this.project = project[0];
      console.log(this.project);
      Formio.makeStaticRequest(`${environment.tenantServer}/${this.project.name}/access/remote`, 'GET').then(remote => {
        this.remote = remote;
        Formio.makeStaticRequest(`${environment.tenantServer}/${this.project.name}/export`, 'GET', null, {
          headers: {
            'x-remote-token': remote
          }
        }).then(master => {
          this.master = master;
          console.log(this.master);
          this.getTenants();
        })
      });
    });
  }
  getTenants(close?) {
    console.log(result.domain);
    Formio.makeStaticRequest(`${environment.tenantServer}/${this.project.name}/${this.master.resources.tenants.name}/submission`, 'GET', null, {
      headers: {
        'x-remote-token': this.remote
      }
    }).then(tenants => {
      this.tenants = tenants;
      console.log(tenants);
      if (close) {
        close();
      }
    })
  }
  // Available names = https://api.test-${environment.tenantServer}/project/available  post {"name":"lawfirm1"}  response : {available: true}
  createProject(submission, close) {
    console.log(submission.data.compProLawFirmLawFirmNameText.replace(/ /g, '-').toLowerCase());
    const shortName = submission.data.compProLawFirmLawFirmNameText.replace(/ /g, '-').toLowerCase();
    Formio.makeStaticRequest(`${AppConfig.apiUrl}/project`, 'POST',
      {
        'title': submission.data.compProLawFirmLawFirmNameText,
        'project': environment.live,
        'name': shortName,
        'settings': {
          'cors': '*'
        }
      },  {
        headers: {
          'x-remote-token': this.remote
        }
      }).then(stage => {
      const profile = new Formio(`${environment.tenantServer}/${shortName}/companyprofile`);
      const admin = new Formio(`${environment.tenantServer}/${shortName}/administratorprofile`);
      this.stage = stage;
      const formio = new Formio(`${environment.tenantServer}/${this.project.name}/${this.master.resources.tenants.name}`);
      submission.data.stageId = stage._id;
      // submission.data.tenantUrl = `https://${shortName}.${result.domain}${result.type ? '.' + result.type : ''}/`;
      submission.data.tenantUrl = shortName;
      const adminSubmission = {data: {
          appUrl: `http://${shortName}.legallogix.com.au`,
          solicitorProfileResourceSolicitorFirstNameText: submission.data.compProLawFirmFirstNameText,
          solicitorProfileResourceSolicitorLastNameEmail: submission.data.compProLawFirmLastNameText,
          email: submission.data.compProLawFirmAdminPersonEmailEmail
        }};
      formio.saveSubmission(submission).then(() => {
        profile.saveSubmission({data: submission.data}).then(() => {
          admin.saveSubmission(adminSubmission).then(() => {
            this.getTenants(close);
          });
        });
      })
    });
  }

  ngOnInit() {
    this.getProject();
  }

}
