import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {FormioAuthService} from 'angular-formio/auth';
import {AppConfig, result} from '../../config';
import {Formio} from 'formiojs';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

  constructor(public _service: FormioAuthService, public _router: Router) {
    this._service.ready.then(() => {
      if (result.subdomain === 'formio') {
        console.log(result);
        if (this._service.authenticated) {
          this._router.navigate(['/slladmin', this._service.user._id, environment.master, 'dashboard']);
        }
      } else {
        if (this._service.is.client) {
          if (this._service.authenticated) {
            if (!this._service.user.data.isNew) {
              this._router.navigate(['/case/welcome']);
            } else {
              const clientCase = new Formio(`${AppConfig.appUrl}/case/submission/`);
              clientCase.loadSubmissions({params: {
                  'data.clientId': this._service.user._id
                }})
                .then((submission) => {
                  this._router.navigate(['/client', submission[0]._id]);
                })
            }
          }
        } else if (this._service.is.administrator || this._service.is.solicitor) {
          if (!this._service.user.data.verified) {
            console.log('why?');
            this._router.navigate(['/auth/verify']);
          } else {
            this._router.navigate(['/admin', this._service.user._id, 'matter']);
          }
        }
      }

    })
  }

  ngOnInit() {
  }

}
