import { Component, OnInit } from '@angular/core';
import {Formio} from 'formiojs';
import {environment} from '../../../environments/environment';
import {FormioAuthService} from 'angular-formio/auth';
import * as moment from 'moment';

@Component({
  selector: 'app-spadmin',
  templateUrl: './spadmin.component.html',
  styleUrls: ['./spadmin.component.scss']
})
export class SpadminComponent implements OnInit {
  ready = false;
  currentDate: any;
  constructor(protected _auth: FormioAuthService) { }
  // Steps to handle tenant CRUD.
    // Login as Super Admin
    // Get Request to main formio project. to get Project settings.
    // Get Portal Secret
    // Hit current endpoint
    // Get x-remote-token
  ngOnInit() {
    this._auth.ready.then(res => {
      this.currentDate = moment().format('LLLL');
      this.ready = res;
    })
  }

}
