import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppConfig} from '../../../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormioAuthService} from 'angular-formio/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../shared/formio/form.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Formio} from 'formiojs';
import {Location} from '@angular/common';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: [
      './client.component.scss',
    './../../../assets/css/global.scss',
    './../../../assets/css/modals.scss'
  ]
})
export class ClientComponent implements OnInit {
  @ViewChild('cards') cards: ElementRef;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/pdf',
      'x-jwt-token': localStorage.getItem('formioToken')
    })
  };
  public formio: any;
  public returnForm: any;
  public returnSubmission: any;
  public totalProgress: any = 0;
  public client: any = {
    data: {
      firstName: "...",
      surName: "...",
    }
  };
  public clientCase: any;
  public currentCase: any;
  public docs: any;
  public params: any;
  public forms: any[] = [];
  public modal = {
    open: false,
    view: true,
  };
  constructor(
    public auth: FormioAuthService,
    public service: FormService,
    public http: HttpClient,
    protected location: Location,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal
  ) {}
  // show modal
  modalOpen () {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-brief-modal-bg-close');
    document.getElementById('jm-brief-modal').classList.remove('jm-brief-modal-close');

    document.getElementById('jm-brief-modal-bg').classList.add('jm-brief-modal-bg-open');
    document.getElementById('jm-brief-modal').classList.add('jm-brief-modal-open');
    // this.modal.open = true;
  }
  modalClose () {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-brief-modal-bg-open');
    document.getElementById('jm-brief-modal').classList.remove('jm-brief-modal-open');

    document.getElementById('jm-brief-modal-bg').classList.add('jm-brief-modal-bg-close');
    document.getElementById('jm-brief-modal').classList.add('jm-brief-modal-close');
    // this.modal.open = false;
  }
  // toggle the view in the modal
  showFull () {
    this.modal.view = true;
  }
  showSum () {
    this.modal.view = false;
  }
  // open print window
  print() {
    // scrape styles and DOM from this page
    const documentDOM = document.getElementById('brief-comp-print').innerHTML;
    const documentHEAD = document.getElementsByTagName('head')[0].innerHTML;
    // call a new window
    const printWin =  window.open('', '', 'width=1000,height=700' );
    printWin.document.write(`
          <html>
            <head>
            ${documentHEAD}
            </head>
            <body>
            ${documentDOM}
            </body>
            <script>
               window.print(); // call print
               window.close(); // call window close after print is called
            </script>
          </html>`);

  }

  // Get Forms
  getForms() {
    this.service.getForms('&tags__in=template').subscribe((forms) => {
      // console.log("forms",forms);
      this.getCaseId(forms);
    })
  }

  /**
   * function gets the users profile and stores their information in the model
   * @param  clientId the clientId - which AIP wise is the clientProfileId
   * @return         null - will run async
   */
  getClient (clientId) {  // where client id = clientProfileId
    let req = new Formio(`${AppConfig.appUrl}/clientprofile/submission/${clientId}`);
        req.loadSubmission().then((cleintProfile) => {
          this.client = cleintProfile
        });
  }
  // Get Case ID
  getCaseId(forms) {
    this.route.params.subscribe(params => {
      this.params = params;
      this.clientCase = new Formio(`${AppConfig.appUrl}/case/submission/${this.route.snapshot.params.caseId}`);
      this.clientCase.loadSubmission().then((submission) => {
        this.currentCase = submission;
        this.getClient(this.currentCase.data.clientId)
        this.getData(forms);
      })
    });
  }
  // Get Return Values
  getReturn() {
    if (localStorage.getItem('form') && localStorage.getItem('submission')) {
      this.returnForm = JSON.parse(localStorage.getItem('form'));
      this.returnSubmission = JSON.parse(localStorage.getItem('submission'));
    }
  }
  goToReturn() {
    this.router.navigate(
      [`/client/${this.currentCase._id}/view/${this.returnForm.name}/${this.returnSubmission && this.returnSubmission._id ?
        this.returnSubmission._id : 'create'}`])
  }
  // Get Case Data
  getData(forms) {
    forms.forEach((form) => {
      // console.log(this.currentCase.data[form.name]);
      if (this.currentCase.data[form.name]) {
        const clientCase = new Formio(`${AppConfig.appUrl}/${form.name.toLowerCase()}/submission/${this.currentCase.data[form.name]._id}`)
          .loadSubmission().then((submission) => {
            if (form.name === 'generalProfile') {
              window.localStorage.setItem('profile', JSON.stringify(submission));
            }
            form.submissionData = submission;
            // console.log(submission);
            this.totalProgress += form.submissionData ? parseInt(form.submissionData.data.totalProgress) : 0;
          });
        this.forms.push(form);
      } else {
        // console.log(form);
        this.forms.push(form);
      }
    });
    // console.log(this.forms);
  }
  /**
   * function converst % into a 3 diget number
   * @return returns string
   */
  toParcent(parcent) {
    if (!isNaN(parcent)) {
      const result = Math.floor(parcent);
      if (result < 100) {
        return ' ' + result;
      } else {
        return result;
      }
    } else {
      return '  0.0'
    }
  }

  // Open Documents modal
  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      // console.log(`Closed with: ${result}`);
    }, (reason) => {
      // console.log(`Dismissed ${reason}`);
    });
  }

  ngOnInit() {
    this.getForms();
    this.getReturn();
  }

}


