import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormioAuthService } from 'angular-formio/auth';
import {AppConfig} from '../../../config';
import {Formio} from 'formiojs';
import FormioUtils from 'formiojs/utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: [
    './archive.component.scss',
    './../../../assets/css/global.scss',
    './../../../assets/css/modals.scss'
  ]
})
export class ArchiveComponent implements OnInit {

  matters: any[] = [];
  status: any[] = [];
  formio: any;
  resource: string;
  params: any;
  src: string;
  editsrc: string;
  currentDate: any;
  ready = false;
  
  closeModalId = "";
  clientViewData = {
    name: "",
    email: "",
    phone: "",
  };
  solicitorViewData = {
    name: "",
    email: "",
    phone: "",
  };
    
  constructor(
    private modalService: NgbModal,
    public _auth: FormioAuthService,
    private _router: ActivatedRoute,
    private router: Router,  
  ){}  
  getMatters() {
    this.formio = new Formio(`${AppConfig.appUrl}/matter`);
    this.formio.loadForm().then(form => {
      FormioUtils.eachComponent(form.components, (component) => {
        if (component.key === 'status') {
          console.log(component);
          this.status =  component.data.values;
        }
      })
    });
    this.formio.loadSubmissions({params: {
        'data.archive': true
      }}).then(matters => {
      this.matters = matters;
      console.log(matters);
    })
  }
  changeStatus(matter, status) {
    matter.data.status = status;
    this.formio.saveSubmission(matter).then(submission => {
      console.log(submission);
      this.getMatters();
    })
  }
  archived(matter) {
    matter.data.archive = false;
    this.formio.saveSubmission(matter).then(submission => {
      console.log(submission);
      this.getMatters();
    })
  }
  
  /*
    show modal function 
   */
  modalOpen (id) {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-close')
    document.getElementById(id).classList.remove('jm-modal-close')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-open')
    document.getElementById(id).classList.add('jm-modal-open')
    this.closeModalId = id // the close id for the modal
  }
  /*
    close modal function 
   */
  modalClose () {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-open')
    document.getElementById(this.closeModalId).classList.remove('jm-modal-open')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-close')
    document.getElementById(this.closeModalId).classList.add('jm-modal-close')
  }


  openViewSolicitor (solicitorId) {
      let formio =  new Formio(`${AppConfig.appUrl}/solicitorprofile/submission/${solicitorId}`);
      this.solicitorViewData.name = '';
      this.solicitorViewData.email = '';
      this.solicitorViewData.phone = '';
      formio.loadForm().then((form) => { // fetch form strcture
        formio.loadSubmission().then((submission) => { // fetch data
          console.log(submission)
          this.solicitorViewData.name = `${submission.data.solicitorProfileResourceSolicitorFirstNameText} ${submission.data.solicitorProfileResourceSolicitorLastNameEmail}`
          this.solicitorViewData.email = `${submission.data.solicitorProfileResourceSolicitorDepartmentText}`
          this.solicitorViewData.phone = `${submission.data.solicitorProfileResourceSolicitorPhoneNumberPhone}`
        });
      });
      this.modalOpen('viewSolicitorForm');
      //solicitorViewData
      
  }
  // ${AppConfig.appUrl}/${resource}/submission/${id}`;
  // solicitorprofile
  
  openViewClient(clientId) {
    let formio =  new Formio(`${AppConfig.appUrl}/clientprofile/submission/${clientId}`);
    this.clientViewData.name = '';
    this.clientViewData.email = '';
    this.clientViewData.phone = '';
    formio.loadForm().then((form) => { // fetch form strcture
      formio.loadSubmission().then((submission) => { // fetch data
        console.log(submission)
        this.clientViewData.name = `${submission.data.firstName} ${submission.data.surName}`
        this.clientViewData.email = `${submission.data.email}`
        this.clientViewData.phone = `${submission.data.clientProfileResourceClientPhoneNumberPhone}`

      });
    });
    // skip and open modal
    this.modalOpen('openViewClientModal');
  }
  
  openDashPage (childRoute) {
    this.router.navigateByUrl(`/admin/${this.params.adminId}}/${childRoute}`);
  }
  
  ngOnInit() {
    this._auth.ready.then(res => {
      this.currentDate = moment().format('LLLL');
      this.ready = res;
    })
    this._router.params.subscribe(params => {
      console.log("this.params:",params)
      this.params = params;
      this.getMatters();
    });
  }

}
