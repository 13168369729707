import { Injectable } from '@angular/core';
import {FormioAppConfig} from 'angular-formio';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {catchError} from 'rxjs/operators';
import {StorageService} from '../storage/storage.service';
import {of} from 'rxjs/observable/of';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'x-jwt-token': ''
  })
};

@Injectable()
export class FormService {
  private formURl: string;
  constructor(
    private http: HttpClient,
    private config: FormioAppConfig,
    private storage: StorageService
  ) {
    this.formURl = `${this.config.appUrl}/form?type=form`;
    httpOptions.headers['x-jwt-token'] = this.storage.getToken().subscribe((token) => (token));
  }
  getForms(query): Observable<any[]> {
    return this.http.get<any[]>(`${this.formURl}${query}`, httpOptions)
      .pipe(
        catchError(this.handleError('getForms', []))
      );
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
