import {ViewEncapsulation, Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {saveAs} from 'file-saver';
import {AppConfig} from '../../../../config';
import {ActivatedRoute, Router} from '@angular/router';
import {FormioAuthService} from 'angular-formio/auth';
import {FormService} from '../../../shared/formio/form.service';
import {Formio} from 'formiojs';
import FormioUtils from 'formiojs/utils';
import htmlToImage from 'html-to-image';

@Component({
  selector: 'app-admin-print',
  templateUrl: './print.component.html',
  styleUrls: [
    './print.component.scss',
    './../../../../assets/css/global.scss'
  ],
  encapsulation:ViewEncapsulation.None
})
export class PrintComponent implements OnInit {
  public formio: any;
  public totalProgress: any = 0;
  public client: any;
  public clientCase: any;
  public currentCase: any;
  public questions: any[] = [];
  public printComponents: any = {};
  public docs: any;
  public forms: any[] = [];
  public one = false;
  public callPrint:boolean = false;
  constructor(
    public auth: FormioAuthService,
    public router: Router,
    public route: ActivatedRoute,
    public service: FormService,
    private _location: Location
  ){}
  backClicked() {
    this._location.back();
  }


  downloadBrief() {
  }
  stripTags (text){
    if (text.replace){
      return text.replace(/<[^>]+>/g, '');
    } else{
      return text;
    }
  }


  // Get Forms
  getForms() {
    const form = new Formio(`${AppConfig.appUrl}/brief`);
    form.loadForm().then((brief) => {
      this.service.getForms('&tags__in=dashboard').subscribe((forms) => {
        this.getCaseId(forms, brief);
      });
    })
  }

  // Get Case ID
  getCaseId(forms, brief) {
    if (this.route.snapshot.params.caseId) {
      this.clientCase = new Formio(`${AppConfig.appUrl}/case/submission/${this.route.snapshot.params.caseId}`);
      this.clientCase.loadSubmission().then((submission) => {
        // Check Valid Token if not redirect.
        this.currentCase = submission;
        this.getData(forms, brief);
      })
    }
  }
  // Get Case Data
  getData(forms, brief) {
    // console.log(brief);
    brief.components.forEach(component => {
      if (component.components) {
        const group = {section: {}, subsection: []};
        group.section = component;
        component.components.forEach((subsections, i) => {
          group.subsection.push({group: subsections, questions: [], answers: {}});
          if ((subsections.components && subsections.components.length > 0) || (subsections.rows && subsections.rows.length > 0)) {
            if (subsections.type === 'table') {

              subsections.rows.forEach((r) => {
                group.subsection[i].questions.push({comp: subsections, quest: r})
              })
            } else {

              subsections.components.forEach(q => {
                if (q.type === 'htmlelement' && q.tags.includes('header')) {
                  group.subsection[i].title = q.content
                } else {
                  group.subsection[i].questions.push(q)
                }
              });
            }
          } else {
            if (subsections.type === 'htmlelement' && subsections.tags.includes('header')) {
              group.subsection[i].title = subsections.content
            }
          }
          //
          // group.subsection.push({group: questions, questions: [], answers: {}});
          // questions.components.forEach(q => {
          //   group.subsection[i].questions.push(q)
          // });
        });
        this.questions.push(group);
      }
    })
    forms.forEach((form) => {
      if (this.currentCase.data[form.name]) {
        const clientCase = new Formio(`${AppConfig.appUrl}/${form.name}/submission/${this.currentCase.data[form.name]._id}`)
          .loadSubmission().then((submission) => {
            this.questions.forEach(question => {
              question.subsection.forEach((sub) => {
                if (sub.group && sub.group.tags.indexOf(form.name) !== -1) {
                  for (const key in submission.data) {
                    if (typeof submission.data[key] === 'string') {
                      sub.answers[key] = submission.data[key].replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => { return str.toUpperCase(); });
                    }
                    if (Array.isArray(submission.data[key])) {
                      submission.data[key].forEach((ans, i) => {
                        if (typeof ans === 'string') {
                          if (ans) {
                            sub.answers[key] += (submission.data[key].length - 1 === i || submission.data[key].length === 1) ?
                              `${ans[key]}` : `${ans[key]}, ` ;
                          }
                        } else {
                          for (const k in ans) {
                            if (Array.isArray(sub.answers[k])) {
                              sub.answers[k].push(ans[k])
                            } else {
                              sub.answers[k] = [];
                              sub.answers[k].push(ans[k]);
                            }
                          }
                        }
                      })
                    } else if (typeof submission.data[key] === 'object') {
                      for (const matrix in submission.data[key]) {
                        if (typeof submission.data[key][matrix] === 'boolean') {
                          submission.data[key][matrix] ?  sub.answers[matrix] = 'Yes' : sub.answers[matrix] = 'No';
                        } else {
                          sub.answers[matrix] = submission.data[key][matrix].replace(/([A-Z])/g, ' $1')
                            .replace(/^./, (str) => { return str.toUpperCase(); });
                        }

                      }
                    }
                    if (submission.data[key].formatted_address) {
                      sub.answers[key] = submission.data[key].formatted_address;
                    } else {
                      sub.answers[key] = submission.data[key]
                    }
                  }
                }
              })
            })
          });
        this.forms.push(form);
      } else {
        this.forms.push(form);
      }

    });
  }
  log(cel){
    if (!this.one){
      this.one = true;
      // console.log(cel)
    }
    return "test table cell";
  }
  checkTags(questions, sub) {
    let response;
    if (questions.tags[0] && sub.answers[questions.tags[0]] && sub.answers[questions.tags[0]].length > 0) {
      let str = ``;
      // str = `<table class="table table-bordered"><tbody>`;
      sub.answers[questions.tags[0]].forEach(table => {
        questions.rows.forEach(comp => {
          if (comp.length > 0) {
            str += `<div class="question-group">`;
            comp.forEach(component => {
              component.components.forEach(row => {
                if ((row.tags.length > 0 && row.tags.includes('question')) &&  table[row.key]) {
                  str += `<div class="question gray-bg">`+this.stripTags(row.content)+`</div>`;
                  str += `<div class="table-answer">`;
                }
                if (row.tags.length > 0 && row.tags[1] && (!row.tags.includes('question') && row.tags.includes('text'))) {
                  if (table[row.tags[1]]) {
                    str += `<div class="table-answer-cell">`+this.stripTags(table[row.tags[1]])+`</div>`;
                  }
                }
              })
            });
            str += `</div></div>`;
          }
        });
      });
      // str += `</tbody></table>`;
      response = str;
    }
    return response;
  }

  ngOnInit() {
    this.getForms();
    this.downloadBrief();

  }

}
