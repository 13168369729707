import { Component, OnInit } from '@angular/core';
import {AppConfig} from '../../../../config';
import {Formio} from 'formiojs';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormioAuthService} from 'angular-formio/auth';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  form: any;
  submission: any = {data: {}};
  client: any;

  constructor(protected location: Location, private modalService: NgbModal,
              private _router: Router, private _route: ActivatedRoute, public _auth: FormioAuthService) { }
  getForm(id) {
    this.form = new Formio(`${AppConfig.appUrl}/clientprofile/submission/${id}`);
    console.log(this.form);
    this.form.loadSubmission().then(client => {
      this.client = client;
    })
  }
  open(template) {
    this.modalService.open(template);
  }
  scroll(id) {
    const el = document.getElementById(id);
    el.scrollIntoView();
  }
  onSubmit(submission) {
    this.location.back();
  }
  deleteClient(c) {
    this.client.data.trash = true;
    this.form.saveSubmission(this.client).then(() => {
      c();
      this.location.back();
    });
  }
  ngOnInit() {
    this._route.params.subscribe(params => {
      this.getForm(params.clientId);
    })
  }

}
