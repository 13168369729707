import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioAuthService } from 'angular-formio/auth';
import { AppConfig } from '../../../config';
import { Formio } from 'formiojs';
import FormioUtils from 'formiojs/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-matter',
  templateUrl: './matter.component.html',
  styleUrls: [
    './matter.component.scss',
    './../../../assets/css/global.scss',
    './../../../assets/css/modals.scss'
  ]
})
export class MatterComponent implements OnInit {
  matters: any[] = [];
  status: any[] = [];
  formio: any;
  submission: any = {data: {}};
  resource: string;
  params: any;
  src: string;
  editsrc: string;
  currentDate: any;
  ready = false;

  closeModalId = "";
  clientViewData = {
    name: "",
    email: "",
    phone: "",
  };
  solicitorViewData = {
    name: "",
    email: "",
    phone: "",
  };
  
  newMatterForm = {};
  constructor(
    private modalService: NgbModal,
    public _auth: FormioAuthService,
    private _router: ActivatedRoute,
    private router: Router,
  ) {}
  getMatters() {
    this.formio = new Formio(`${AppConfig.appUrl}/matter`);
    this.formio.loadForm().then(form => {
      FormioUtils.eachComponent(form.components, (component) => {
        if (component.key === 'status') {
          console.log(component);
          this.status =  component.data.values
        }
      })
    });
    this.formio.loadSubmissions({params: {
      'data.archive__ne': true
      }}).then(matters => {
      this.matters = matters;
      console.log(matters);
    })
  }
  changeStatus(matter, status) {
    matter.data.status = status;
    this.formio.saveSubmission(matter).then(submission => {
      console.log(submission);
      this.getMatters();
    })
  }
  archived(matter) {
    matter.data.archive = true;
    this.formio.saveSubmission(matter).then(submission => {
      console.log(submission);
      this.getMatters();
    })
  }
  
  openModal(template, resource) {
    this.resource = resource;
    this.src = `${AppConfig.appUrl}/${resource}`;
    this.modalService.open(template, { size: 'lg' });
  }
  onMatterSubmit(submission, c) {
    if (this.resource === 'matter') {
      const caseResource = new Formio(`${AppConfig.appUrl}/case`);
      const caseSubmission = {
        data: {
          clientId: submission.data.client._id,
          clientEmail: submission.data.client.data.email,
          matterId: submission._id
        },
        owner: submission.data.client._id
      };
      caseResource.saveSubmission(caseSubmission).then(caseResponse => {
        submission.data.caseId = caseResponse._id;
        const resubmit = new Formio(this.src);
        resubmit.saveSubmission(submission).then(res => {console.log(res);
          c();
          this.getMatters();
        });
      });
    }
  }
  openEdit(template, resource, id) {
    this.resource = resource;
    this.editsrc = `${AppConfig.appUrl}/${resource}/submission/${id}`;
    this.modalService.open(template, { size: 'lg' });
  }
  
  openMatter (params,matter) {
    // routerLink="/admin/{{params.adminId}}/matter/{{matter._id}}/case/{{matter.data.caseIdthis.router.navigateByUrl('/login');
    let id = this._auth.user._id
    this.router.navigateByUrl(`/admin/${id}}/matter/{{matter._id}}/case/${matter.data.caseId}}`);
  }
  
  openDashPage (childRoute) {
    let id = this._auth.user._id
    this.router.navigateByUrl(`/admin/${id}/${childRoute}`);
  }
  
  logClient(client){
    // console.log(client)
    // return ""
  }
  
  /*
    show modal function 
   */
  modalOpen (id) {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-close')
    document.getElementById(id).classList.remove('jm-modal-close')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-open')
    document.getElementById(id).classList.add('jm-modal-open')
    this.closeModalId = id // the close id for the modal
  }
  /*
    close modal function 
   */
  modalClose () {
    document.getElementById('jm-brief-modal-bg').classList.remove('jm-modal-bg-open')
    document.getElementById(this.closeModalId).classList.remove('jm-modal-open')
    
    document.getElementById('jm-brief-modal-bg').classList.add('jm-modal-bg-close')
    document.getElementById(this.closeModalId).classList.add('jm-modal-close')
  }
  
  openCreateMatter(clientId) {
    let formio =  new Formio(`${AppConfig.appUrl}/matter`);
    formio.loadForm().then((form) => { // fetch form strcture
      this.newMatterForm = form;
      console.log(form)
    });
    // skip and open modal
    this.modalOpen('newMatterModal');
  }
  
  openViewSolicitor (solicitorId) {
      let formio =  new Formio(`${AppConfig.appUrl}/solicitorprofile/submission/${solicitorId}`);
      this.solicitorViewData.name = '';
      this.solicitorViewData.email = '';
      this.solicitorViewData.phone = '';
      formio.loadForm().then((form) => { // fetch form strcture
        formio.loadSubmission().then((submission) => { // fetch data
          console.log(submission)
          this.solicitorViewData.name = `${submission.data.solicitorProfileResourceSolicitorFirstNameText} ${submission.data.solicitorProfileResourceSolicitorLastNameEmail}`
          this.solicitorViewData.email = `${submission.data.solicitorProfileResourceSolicitorDepartmentText}`
          this.solicitorViewData.phone = `${submission.data.solicitorProfileResourceSolicitorPhoneNumberPhone}`
        });
      });
      this.modalOpen('viewSolicitorForm');
      //solicitorViewData
      
  }
  // ${AppConfig.appUrl}/${resource}/submission/${id}`;
  // solicitorprofile
  
  openViewClient(clientId) {
    let formio =  new Formio(`${AppConfig.appUrl}/clientprofile/submission/${clientId}`);
    this.clientViewData.name = '';
    this.clientViewData.email = '';
    this.clientViewData.phone = '';
    formio.loadForm().then((form) => { // fetch form strcture
      formio.loadSubmission().then((submission) => { // fetch data
        console.log(submission)
        this.clientViewData.name = `${submission.data.firstName} ${submission.data.surName}`
        this.clientViewData.email = `${submission.data.email}`
        this.clientViewData.phone = `${submission.data.clientProfileResourceClientPhoneNumberPhone}`

      });
    });
    // skip and open modal
    this.modalOpen('openViewClientModal');
  }
  
  ngOnInit() {
    this._auth.ready.then(res => {
      this.currentDate = moment().format('LLLL');
      this.ready = res;
    })
    this._router.params.subscribe(params => {
      console.log("this.params:",params)
      this.params = params;
      this.getMatters();
    });
  }

}
