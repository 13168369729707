import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {Formio} from 'formiojs';
import {FormioAuthService} from 'angular-formio/auth';
import {AppConfig, result} from '../config';
import {environment} from '../environments/environment';

const global = window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(public _auth: FormioAuthService, private _router: Router) {
    this._auth.onLogin.subscribe(() => {
      console.log(result.subdomain);
      console.log(this._auth);
      if (result.subdomain === 'formio') {
        console.log(result);
        this._router.navigate(['/slladmin', this._auth.user._id, environment.master, 'dashboard']);
      } else {
        this._auth.ready.then(() => {
          console.log(this._auth);
          if (this._auth.is.client) {
            if (this._auth.user.data.verified) {
              this._router.navigate(['/case/welcome']);
            } else if (!this._auth.user.data.isNew && !this._auth.user.data.verified) {
              console.log('why?');
              this._router.navigate(['/auth/verify']);
            }
          } else if (this._auth.is.administrator || this._auth.is.solicitor) {
          if (!this._auth.user.data.verified) {
              console.log('why?');
              this._router.navigate(['/auth/verify']);
            } else {
            this._router.navigate(['/admin', this._auth.user._id, 'matter']);
          }
          }
        })
      }
    });
    this._auth.onLogout.subscribe(() => {
      this._router.navigate(['/auth/login']);
    });
    this._auth.onRegister.subscribe(() => {
      this._router.navigate(['/auth/verify']);
    });
  }

}

