import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientComponent } from './client.component';
import {ClientRoutingModule} from './client-routing.module';
import {SharedModule} from '../shared/shared.module';
import {FormioAuthService} from 'angular-formio/auth'
import {StorageService} from '../shared/storage/storage.service';
import {FormService} from '../shared/formio/form.service';
import {HttpClientModule} from '@angular/common/http';
import {LocalStorageModule} from '@ngx-pwa/local-storage';
import {FormioModule} from 'angular-formio';
import { FormviewComponent } from '../formview/formview.component';
import { DocumentsComponent } from './documents/documents.component';
import { PrintComponent } from './print/print.component';
import { UnsureComponent } from './unsure/unsure.component';
import { SummaryComponent } from './summary/summary.component';

@NgModule({
  imports: [
    CommonModule,
    ClientRoutingModule,
    HttpClientModule,
    LocalStorageModule,
    SharedModule,
    FormioModule
  ],
  declarations: [ClientComponent, FormviewComponent, DocumentsComponent, PrintComponent, UnsureComponent, SummaryComponent],
  providers: [
    StorageService,
    FormService
  ]
})
export class ClientModule { }
