import { FormioAppConfig } from 'angular-formio';
import { FormioAuthConfig } from 'angular-formio/auth';
import {environment} from './environments/environment';
import {Formio} from 'formiojs';

export const result = {
  domain: '',
  type: '',
  subdomain: ''
};

const full = window.location.host;
const parts = full.split('.');
result.subdomain = parts[0];
result.domain = parts[1];
result.type = parts[1] === parts[parts.length - 1] ? '' : parts[parts.length - 1];
Formio.namespace = result.subdomain;
console.log(result.subdomain);
// switch to Formio. when tenant subdomain is === 'formio'
// This is pointing to the Deployed env
let appUrl = `${environment.tenantServer}/${result.subdomain}`;
let apiUrl = `${environment.tenantServer}`;

// When the subdomain is legallogix as "enterprise.legallogix.com.au" will point to enterprise stage
if (result.subdomain === 'enterprise') {
  appUrl = `${environment.tenantServer}/enterprise`;
}
// If the Tenant Server is pointing to form.io will change the structure and also will get the subdomain for example
// focus-group.form.io
if (environment.tenantServer === 'form.io') {
  appUrl = `https://${result.subdomain}.${environment.tenantServer}`;
  apiUrl = 'https://api.form.io';
}

// when the subdomain is formio "superadmin" it will change to formio.form.io
if (result.subdomain === 'formio') {
   appUrl = `https://${result.subdomain}.form.io`;
   apiUrl = 'https://api.form.io';
}
// When the app is on local lets point it to dev.
if (result.subdomain === 'localhost:4200') {
   appUrl = `https://dev-lmbrfxpohthymfd.form.io`;
   apiUrl = 'https://api.form.io';
}

  // appUrl = 'https://dev-lmbrfxpohthymfd.form.io'; // b2b test bed - zcr



export const AppConfig: FormioAppConfig = {
  appUrl: appUrl,
  apiUrl: apiUrl,
  icons: 'fontawesome'
};

export const AuthConfig: FormioAuthConfig = {
  login: {
    form: 'user/login'
  },
  register: {
    form: 'user/register'
  }
};

export const Forms = {
  purchaseOrder: {
    form: 'user/login'
  },
  orderAlgo: {
    form: 'user/register'
  }
};
