import { Component, OnInit } from '@angular/core';
import { Formio } from 'formiojs';
import { ActivatedRoute, Router} from '@angular/router';
import {AppConfig} from '../../../config';

@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})
export class VerifiedComponent implements OnInit {
  src: string;
  submission: any = {data: {}};
  user: any;
  resource: any;
  constructor(public router: Router, public route: ActivatedRoute) {
    console.log(this.route.snapshot.queryParams);
  }
  setToken() {
    this.src = `${AppConfig.appUrl}/createpassword`;
    this.route.queryParams.subscribe(params => {
      this.resource = params.res;
      Formio.setToken(params.token).then((user) => {
        this.user = user;
      });
    });
  }
  onSubmit(submission) {
    this.user.data.password = this.submission.data.password;
    this.authenticated(this.user);
  }
  authenticated(user) {
    const submission = user;
    console.log(submission);
    const resource = new Formio(`${AppConfig.appUrl}/${this.resource}/submission/${user._id}`);
    submission.data.verified = true;
    resource.saveSubmission(submission).then((res) => {
      console.log(res);
      Formio.logout();
      this.router.navigate(['/auth/login']);
    })
  }

  ngOnInit() {
    this.setToken()
  }

}
