import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './admin/admin.component';
import {AuthGuard} from '../shared/auth/auth-guard.service';
import {MatterComponent} from './matter/matter.component';
import {PeopleComponent} from './people/people.component';
import {ArchiveComponent} from './archive/archive.component';
import {CreateComponent} from './people/create/create.component';
import {EditComponent} from './people/edit/edit.component';
import {ClientComponent} from './client/client.component';
import {FormviewComponent} from './client/formview/formview.component';
import {AuthAdminService} from '../shared/auth/auth-admin.service';
import {UnsureComponent} from './client/unsure/unsure.component';
import {DocumentsComponent} from './client/documents/documents.component';


const routes: Routes = [
  {
    path: ':adminId',
     component: AdminComponent,
    data: {
      title: 'Admin'
    },
    children: [
      {
        path: 'matter',
        component: MatterComponent
      },
      {
        path: 'people',
        component: PeopleComponent
      },
      {
        path: 'archive',
        component: ArchiveComponent
      }
      ],
    canActivate: [AuthGuard]
  },
  {
    path: ':adminId/people/create',
    component: CreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':adminId/people/edit/:clientId',
    component: EditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':adminId/matter/:matterId/case/:caseId',
    component: ClientComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: ':adminId/matter/:matterId/case/:caseId/view/:formname/:submissionId',
    component: FormviewComponent,
    data: {
      title: 'Form'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':adminId/matter/:matterId/case/:caseId',
    component: ClientComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: ':adminId/matter/:matterId/case/:caseId/documents',
    component: DocumentsComponent,
    data: {
      title: 'Form'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':adminId/matter/:matterId/case/:caseId/unsure',
    component: UnsureComponent,
    data: {
      title: 'Form'
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
